body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  width: 100%;
  height: 100vh;
}

#PaginationIcon {
  cursor: pointer;
  margin: 0 5px;
  color: #7aa3e8;
  transition: all 0.2s ease-in;
}

#PaginationIcon:hover {
  color: #6174b9;
}

#HeaderIcon {
  margin-right: 2rem;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

#HeaderIcon:hover {
  filter: drop-shadow(0 0 5px white);
}

#LogoutIcon {
  cursor: pointer;
  transition: all 0.2s ease-in;
}

#LogoutIcon:hover {
  filter: drop-shadow(0 0 5px white);
}
